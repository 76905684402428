<template>
  <exchange />
</template>

<script>
import exchange from '@/views/vip/base/scoreExchangeRule'
export default {
  name: 'exchange',
  components: { exchange }
}
</script>
